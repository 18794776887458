import React from 'react'
import {Row,Col} from "antd"
import style from "./style.module.scss";
import {BrandBannerProps} from "./types"
import Link from "next/link";

 const BrandBanner = ({description,brandItems,bannerColor}:BrandBannerProps) => {
  return (
    <div>
        <Row className={style.CategoriesWithImage} >
          <div className={`${style.CardContainer} container mt-32`} style={{backgroundColor:bannerColor}}>
            <div className={`font-regular-h4 lh-lg text-white ${style.description}`}
                   dangerouslySetInnerHTML={{
                    __html: description
                  }}
                />
            <div className={style.roundedImgWrapper}>
              <Row>
                {brandItems &&
                  brandItems?.map((item, index) => (
                  <Col span={8} xl={4} lg={6}  key={index}>
                     <Link href={item?.redirectUrl || "/"} passHref>
                       <a className="me-16 mb-20">
                        <div
                            className={`${style.roundedItems} p-5`}
                          >
                        <div
                            className={style.roundImage}
                            style={{ backgroundColor: `${item?.backgroundColor}` }}
                        >
                         <img
                            src={item?.brandImage?.url || ""}
                            alt={item?.brandImage?.altText}
                            />
                        </div>
                    </div>
                    </a>
                     </Link>
                  </Col>
                ))}
                </Row>
             </div>
         </div>
        </Row>
    </div>
  )
}
export default BrandBanner;